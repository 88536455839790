import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import watsnew3img from "../../static/HeatMaster_Product_Image.png"
import watsnew4img from "../../static/301p_c_01.png"
import watsnew4doc1 from "../../static/301p_c_01.pdf"
import watsnew5img from "../../static/564_l.jpg"
import watsnew5doc1 from "../../static/Invita+564+Wifi+Thermostat+Sales+Bulletin.pdf"
import watsnew5doc2 from "../../static/Invita+564+Sales+Brochure.pdf"
// import watsnew6img from "../../static/UBS-400-Boiler-Stand_Oct-16,-2018.jpg"
// import watsnew6doc1_1 from "../../static/UBS-400BoilerStand_Nov1_19.pdf"
// import watsnew7img1 from "../../static/instinct-solo-and-combi.png"
import watsnew8 from "../../static/AdeyLineup.png"
import watsnew9img from "../../static/hsci_flame.png"
import watsnew9 from "../../static/NewSalesRepresentativeAnnouncement_JeffHouse_dec1421.pdf"
import watsnew10img from "../../static/axiom-logo-vector-no-industries-ltd.-text-super-large-01.png"
import watsnew11img from "../../static/HEAT-FLO-LOGO.png"
import watsnew11pdf from "../../static/Heat-floProductAnnouncement2022.pdf"
import whatsNewUsBoiler from "../../static/banner4.png"
import whatsNewUsBoilerpdf from "../../static/USBoilerProductAnnouncementWebsite2022.pdf"
import whatsNewVesta from "../../static/banner5.png"
import whatsNewVestapdf from "../../static/VestaAnnouncementWebsite2022.pdf"

const WhatsNewPage = () => (
  <Layout>
    <SEO title="HSCI — Promotions" />
    <h1>What's New?</h1>

    <ul id="promos">
      <li className="promo" id="whatsNewUsboiler">
        <div className="promo-image">
          <img src={whatsNewUsBoiler} alt="" />
        </div>
        <div className="promo-text">
          <h2>
            Introducing U.S. Boiler Company Combi & Heat Only Condensing Boilers
          </h2>
          <p>
            Check out the{" "}
            <a href={"/products/condensing-boilers/us-alta/"}>Alta</a> and{" "}
            <a href={"/products/condensing-boilers/us-aspen/"}>Aspen</a>{" "}
            Condensing Boilers
          </p>
          <strong>
            <a target="_blank" href={whatsNewUsBoilerpdf}>
              <span className="icon link download black" /> Download
              Announcement
            </a>
          </strong>
        </div>
      </li>
      <li className="promo" id="whatsNewUsboiler">
        <div className="promo-image">
          <img src={whatsNewVesta} alt="" />
        </div>
        <div className="promo-text">
          <h2>Introducing Vesta Combi & Heat Only Condensing Boilers</h2>
          <p>
            Check out the{" "}
            <a href={"/products/condensing-boilers/vesta-vrc-combi-boilers/"}>
              Vesta VRC
            </a>{" "}
            and{" "}
            <a
              href={"/products/condensing-boilers/vesta-vrb-heat-only-boilers/"}
            >
              Vesta VRB
            </a>{" "}
            Condensing Boilers
          </p>
          <strong>
            <a target="_blank" href={whatsNewVestapdf}>
              <span className="icon link download black" /> Download
              Announcement
            </a>
          </strong>
        </div>
      </li>

      <li className="promo" id="watsnew11">
        <div className="promo-image">
          <img src={watsnew11img} alt="" />
        </div>
        <div className="promo-text">
          <h2>Heat-flo Products Available Now!</h2>
          <p>
            Heat-flo is an American manufacturer of premium 316L stainless steel
            tanks designed for applications in the hydronic, chilled water and
            domestic hot water industries.
          </p>
          <strong>
            <a target="_blank" href={watsnew11pdf}>
              <span className="icon link download black" /> Download
              Announcement
            </a>
          </strong>
        </div>
      </li>
      <li className="promo" id="watsnew9">
        <div className="promo-image long-image">
          <img
            src={watsnew10img}
            alt="Axios and Hydronic Systems present System Feeders, H20 Demineralizers, Condensate Neutralizers, System Cleaners, PEX-Pal, Sidestrem Filters / CBF, Hydro-NODE "
          />
        </div>
        <div className="promo-text">
          <h2>Axiom Representation Announcement</h2>
          <p>
            We are extremely pleased to announce our agency representation of
            Axiom products in Ontario (except Thunder Bay & Ottawa Regions).
          </p>

          <strong>
            <a
              target="_blank"
              href={"/products/hydronic-accessories/axiom-product-catalog/"}
            >
              See Axiom Products
            </a>
          </strong>
          <p>
            Axiom Industries Ltd. is a privately owned, Canadian manufacturer of
            high-quality glycol feeders, condensate neutralizers,
            de-mineralizers and related hydronic specialty products. Axiom is a
            great fit with our existing hydronic offering and we look forward to
            providing exceptional service and support of Axiom products to our
            wholesale customers.
          </p>
        </div>
      </li>
      <li className="promo" id="watsnew9">
        <div className="promo-image">
          <img src={watsnew9img} alt="New hire" />
        </div>
        <div className="promo-text">
          <h2>New Sales Representative Announcement – Jeff House</h2>
          <p>
            We are excited to announce that Jeff House has joined our team as a
            sales representative and product trainer in the South-Western
            Ontario region from Niagara to Windsor. As a seasoned sales expert
            and technical trainer, Jeff brings a wealth of knowledge, experience
            and enthusiasm to our sales team.
          </p>

          <strong>
            <a target="_blank" href={watsnew9}>
              <span className="icon link download black" /> Download
              Announcement
            </a>
          </strong>
        </div>
      </li>
      <li className="promo" id="watsnew8">
        <div className="promo-image">
          <img src={watsnew8} alt="Adey Lineup" />
        </div>
        <div className="promo-text">
          <h2>ADEY Innovation LLC Products Now Available!</h2>
          <p>
            We are pleased to announce our representation of ADEY Innovation LLC
            products in Eastern Canada.
          </p>

          <p>
            ADEY manufacture a unique line of magnetic dirt filters and water
            treatment products designed for residential and commercial hydronic
            heating systems.
          </p>
          <p>
            The MagnaClean filters specialize in the removal of magnetite and
            iron-oxide on the first pass in hydronic systems. The filters
            capture magnetic and non-magnetic debris to increase efficiency and
            improve component longevity.
          </p>
          <p>
            The ADEY Cleaner is specifically designed to remove system sludge
            and scale to increase efficiency and performance.
          </p>
          <p>
            The ADEY Protector offers system protection against scale, corrosion
            and water hardness.
          </p>
          <strong>
            <a href={"/products/hydronic-accessories/"}>
              See Our Hydronic Accessories
            </a>
          </strong>
        </div>
      </li>
      {/*
      unpublished: dec-21 — remove mar 22
      */}
      {/*<li className="promo" id="watsnew7">*/}
      {/*  <div className="promo-image">*/}
      {/*    <img src={watsnew7img1} alt="Universal Boiler Stand" />*/}
      {/*  </div>*/}
      {/*  <div className="promo-text">*/}
      {/*    <h2>Instinct Solo & Combi High Efficiency Boilers</h2>*/}
      {/*    <p>*/}
      {/*      We are excited to announce the newest addition to the Triangle Tube*/}
      {/*      family of innovative products: the Instinct Solo & Combi high*/}
      {/*      efficiency boiler. The Instinct includes a stainless steel fire tube*/}
      {/*      heat exchanger for central heating and a brazed plate heat exchanger*/}
      {/*      for DHW production.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      Available in five models: 110 Solo, 155 Solo, 199 Solo, 155 Combi,*/}
      {/*      199 Combi*/}
      {/*    </p>*/}
      {/*    <p>Key Features:</p>*/}
      {/*    <ul>*/}
      {/*      <li>Best Factory Warranty in the Industry!!!</li>*/}
      {/*      <li>*/}
      {/*        6 Years Parts and 3 Years Labour Allowance with Registration and*/}
      {/*        Combustion Setup Report**/}
      {/*      </li>*/}
      {/*      <li>FULL 10 Year Primary Heat Exchanger Warranty</li>*/}
      {/*      <li>199 Combi Delivers 5 GPM @ 70 Degree F Rise</li>*/}
      {/*      <li>2 Inch Venting on 110 & 155 Models</li>*/}
      {/*      <li>Stainless Steel Fire Tube Design</li>*/}
      {/*      <li>CTRLmax Touchscreen Control</li>*/}
      {/*      <li>Cascade Capability on Solo Models</li>*/}
      {/*      <li>0-10 Vdc Signal</li>*/}
      {/*      <li>95% Efficiency</li>*/}
      {/*      <li>50 PSI Maximum Working Pressure</li>*/}
      {/*      <li>ASME Certified</li>*/}
      {/*    </ul>*/}
      {/*    <strong>*/}
      {/*      <a href={"/products/residential-boilers/instinct-solo-and-combi/"}>*/}
      {/*        Learn More*/}
      {/*      </a>*/}
      {/*    </strong>*/}
      {/*  </div>*/}
      {/*</li>*/}
      {/*
      unpublished: dec-21 — remove mar 22
      */}
      {/*<li className="promo" id="watsnew6">*/}
      {/*  <div className="promo-image">*/}
      {/*    <img src={watsnew6img} alt="Universal Boiler Stand" />*/}
      {/*  </div>*/}
      {/*  <div className="promo-text">*/}
      {/*    <h2>Universal Boiler Stand</h2>*/}
      {/*    <p>For Triangle Tube Boilers and Cascade Systems</p>*/}

      {/*    <p>Key Features:</p>*/}
      {/*    <ul>*/}
      {/*      <li>Fits all Prestige boiler models</li>*/}
      {/*      <li>Rugged 12 gauge steel tubing</li>*/}
      {/*      <li>No assembly required</li>*/}
      {/*      <li>Black powder coat finish</li>*/}
      {/*      <li>Adjustable levelling legs</li>*/}
      {/*      <li>1 1⁄2” Square tubing</li>*/}
      {/*      <li>Fits Challenger CC 125H & CC 150S models</li>*/}
      {/*      <li>Stackable for multiple boiler installations</li>*/}
      {/*    </ul>*/}
      {/*    <strong>*/}
      {/*      <a target="_blank" href={watsnew6doc1_1}>*/}
      {/*        <span className="icon link download black" /> Download Sales*/}
      {/*        Bulletin*/}
      {/*      </a>*/}
      {/*    </strong>*/}
      {/*  </div>*/}
      {/*</li>*/}
      <li className="promo" id="watsnew5">
        <div className="promo-image">
          <img src={watsnew5img} alt="tekmar&reg; Invita 564" />
        </div>
        <div className="promo-text">
          <h2>tekmar&reg; Invita 564</h2>
          <p>WiFi Thermostat For Boilers, Furnaces &amp; Heat Pumps</p>

          <p>Outstanding Features:</p>
          <ul>
            <li>
              Two wire connection from Invita thermostat to the mechanical room.
            </li>
            <li>
              Compatible with 2 heat / 2 cool / 2 accessories &amp; fan
              conventional systems or 4 heat / 2 cool heat pump systems.
            </li>
            <li>
              Controls relative humidity by operating a humidifier and/or
              dehumidifier.
            </li>
            <li>
              Operates a Heat Recovery Ventilator (HRV) or Energy Recovery
              Ventilator (ERV) to ensure high indoor air quality.
            </li>
            <li>
              Remote access through the Invita Connect App or web browser.
            </li>
            <li>Email alert notifications.</li>
            <li>Watts FloodSafe Water Detector Shutoff.</li>
            <li>Available in two models: 564 White and 564B Black.</li>
          </ul>
          <strong>
            <a target="_blank" href={watsnew5doc1}>
              <span className="icon link download black" /> Download Sales
              Bulletin
            </a>
          </strong>
          <strong>
            <a target="_blank" href={watsnew5doc2}>
              <span className="icon link download black" /> Download Sales
              Brochure
            </a>
          </strong>
        </div>
      </li>
      {/*<li className="promo" id="watsnew4">*/}
      {/*  <div className="promo-image">*/}
      {/*    <img src={watsnew4img} alt="Image of 301P Switching Relay" />*/}
      {/*  </div>*/}
      {/*  <div className="promo-text">*/}
      {/*    <h2>tekmar&reg; Switching Relay 301P</h2>*/}
      {/*    <p>*/}
      {/*      The Switching Relay 301P connects to one thermostat and operates a*/}
      {/*      circulator and boiler to provide heating to a hydronic heating*/}
      {/*      system. The 301P includes optional exercising to prevent circulator*/}
      {/*      seizure when not in use, and optional post purge operation maximize*/}
      {/*      energy savings.*/}
      {/*    </p>*/}

      {/*    <strong>*/}
      {/*      <a href={watsnew4doc1}>*/}
      {/*        <span className="icon link download black" /> Download PDF*/}
      {/*      </a>*/}
      {/*    </strong>*/}
      {/*    <strong>*/}
      {/*      <a href="/products/controls/zone-valve-control-switching-relays">*/}
      {/*        <span className="icon link web black" />*/}
      {/*        See Product Info*/}
      {/*      </a>*/}
      {/*    </strong>*/}
      {/*  </div>*/}
      {/*</li>*/}
      {/*
      unpublished: dec-21 — remove mar 22
      */}
      {/*<li className="promo" id="watsnew3">*/}
      {/*  <div className="promo-image">*/}
      {/*    <img src={watsnew3img} alt="Image of heat master" />*/}
      {/*  </div>*/}
      {/*  <div className="promo-text">*/}
      {/*    <h2>The new HeatMaster TC</h2>*/}
      {/*    <p>*/}
      {/*      We are excited to announce the newest addition to the Triangle Tube*/}
      {/*      family of innovative products: the Heatmaster TC combination*/}
      {/*      high-efficiency boiler and water heater. The Heatmaster TC is the*/}
      {/*      only storage combination boiler in the world to fully condense in*/}
      {/*      both space heating and domestic water heating modes. The Heatmaster*/}
      {/*      TC has a nearly 10-year history of success in the European market*/}
      {/*      and is now being made available in North America. This amazing*/}
      {/*      accomplishment in engineering can be used as a stand-alone high*/}
      {/*      output domestic water heater or as a high output combination*/}
      {/*      boiler/water heater.*/}
      {/*    </p>*/}
      {/*    <p>The Heatmaster TC is available in two sizes: 240 &amp; 399 MBH</p>*/}
      {/*    <ul>*/}
      {/*      <li>Up to 660 Gallons First Hour Domestic Hot Water.</li>*/}
      {/*      <li>Up to 185 degrees F DHW Storage Temperature.</li>*/}
      {/*      <li>All Stainless Steel Construction.</li>*/}
      {/*      <li>Stainless Steel Fire Tube Design.</li>*/}
      {/*      <li>FULL 10 Year Heat Exchanger Warranty.</li>*/}
      {/*      <li>50 Gallon DHW Storage Capacity.</li>*/}
      {/*      <li>Built-In Buffer Tank (30 gallon boiler storage).</li>*/}
      {/*      <li>Exclusive Tank-In-Tank Design.</li>*/}
      {/*      <li>ACV Max Control With Cascade Capability.</li>*/}
      {/*      <li>95% Efficiency.</li>*/}
      {/*      <li>Small Footprint (only 27” x 30”).</li>*/}
      {/*      <li>ASME Certified.</li>*/}
      {/*    </ul>*/}
      {/*    <strong>*/}
      {/*      <a*/}
      {/*        href="https://www.youtube.com/watch?v=whj06I0IMhY"*/}
      {/*        target="_blank"*/}
      {/*      >*/}
      {/*        <span className="icon link web black" />*/}
      {/*        Click here to see a video*/}
      {/*      </a>*/}
      {/*    </strong>*/}
      {/*    <strong>*/}
      {/*      <a href="/products/commercial-boilers/heatmaster-tc">*/}
      {/*        <span className="icon link web black" />*/}
      {/*        See Product Info*/}
      {/*      </a>*/}
      {/*    </strong>*/}
      {/*  </div>*/}
      {/*</li>*/}
    </ul>
  </Layout>
)

export default WhatsNewPage
